import React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import RadioList from './RadioButton.js';
import RadioListImage from './RadioButtonImage.js';
import { Helmet } from 'react-helmet-async';

var slatesAPI = ''; // hit REST API to get list of all slates

if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
{
    slatesAPI = process.env.REACT_APP_UAT_API_SLATES;
}
else if (process.env.REACT_APP_ENVIRONMENT === 'PROD')
{
    slatesAPI = process.env.REACT_APP_PROD_API_SLATES;
}

const Home = () => {
       
    const [sport, setSport] = useState(""); // no default selection as we use the page load to load all slates
    const [oneVsMany, setOneVsMany] = useState("One");
    const [slates, setSlates] = useState([]);
    const [ddlSlates, setDdlSlates] = useState([]);
    const [ddlSlateSelected, setDdlSlateSelected] = useState("");
    const [sportMessage, setSportMessage] = useState("");
    const [emptyArray, setEmptyArray] = useState([]);

    // This 'Next' button displays when at least 1 slate is available
    //      It will take the user to a sport-specific .js page (e.g. HomeNFLFull.js if NFL full slate chosen)
    const [showNext, setShowNext] = useState(true); 

    const getSlates = async () => { 

        // var slateDetails = slates.find(slate => {return slate.value === slateSelected});
        try {
            const responseSlate = await fetch(slatesAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': '', // sport, // leave empty to get all slates here
                    'slateid': 0, // 0 gets all slates that are *not* hidden
                }
            });
            const result = await responseSlate.json();

            if (responseSlate.ok) {
                if (result[0] !== undefined)
                {
                    var tempSlates = result[0];
                    //tempSlates = tempSlates.sort((a, b) => Number(a.order) - Number(b.order));
                    setSlates(tempSlates);
                }
            }
        } catch (err) {
            console.log('Error caught!  Problem getting slates - ' + err.message);
        } finally {
        }
    }

    const onChangeSport = (e) => {

        var currentDate = new Date();

        // for debugging reasons only - go back 5 or so hours to show slates set for later today
        /*
        if (process.env.REACT_APP_ENVIRONMENT === 'UAT') 
        {
            currentDate.setHours(currentDate.getHours() + 4);
        }
        */

        var currentdateUTC = currentDate.toUTCString(); // ALWAYS UTC -- EVERYWHERE!
      
        const { name } = e.target;
        setSport(name);

        // these next 2 lines of code are if you want a large red message above "Slate"  (also change '(2===1)' down below)
            //if (name === "MLB"){setSportMessage("Coming Soon");}
            //else {setSportMessage("");}

        var tempSlates = [];

        tempSlates = slates.filter(slate => {return (slate.sport === name && slate.slateType.toUpperCase() === "CLASSIC" && new Date(slate.displaySlateDate) <= Date.parse(currentdateUTC) && new Date(slate.hideSlateDate) >= Date.parse(currentdateUTC)) });
        
        if (tempSlates.length > 0)
        {
            tempSlates = tempSlates.sort((a, b) => Number(a.order) - Number(b.order));
            setShowNext(true);
            setDdlSlates(tempSlates);
            setDdlSlateSelected(tempSlates[0]);
        }
        else
        {
            setDdlSlates(emptyArray);
            setDdlSlateSelected("");
            setShowNext(false);
        }
    }

    const ddlSlateChangeHandler = (value) => {
        setDdlSlateSelected(value);
    };

    const onChangeOneVsMany = (e) => {
        const { name } = e.target;
        setOneVsMany(name);
    }

    const handleNextClick = () => {
      var slateTypeUpper = String(ddlSlateSelected.slateType);
      var sportUpper = String(sport);
      slateTypeUpper = slateTypeUpper.toUpperCase();
      sportUpper = sportUpper.toUpperCase();

      if (sportUpper === 'NFL' && slateTypeUpper === 'CLASSIC')
      {
        window.location.replace('./HomeNFLFull?slateID=' + ddlSlateSelected.value.toString() + '&lineups=' + oneVsMany.toString());
      }
      else if (sportUpper === 'PGA' && slateTypeUpper === 'CLASSIC')
      {
        window.location.replace('./HomePGAFull?slateID=' + ddlSlateSelected.value.toString() + '&lineups=' + oneVsMany.toString());
      }
      else if (sportUpper === 'MLB' && slateTypeUpper === 'CLASSIC')
      {
        window.location.replace('./HomeMLBFull?slateID=' + ddlSlateSelected.value.toString() + '&lineups=' + oneVsMany.toString());
      }
      /*
      else if (sportUpper === 'NFL' && slateTypeUpper === 'SUPER')
      {
        window.location.replace('./HomeNFLSuper?slateID=' + ddlSlateSelected.value.toString() + '&lineups=' + oneVsMany.toString());
      }
      */
    }

    // This is page load
    useEffect (() => {
        
        getSlates();
        setShowNext(false);
    }, []
    );

    return (
        <div className="content">
            <Helmet>
                <title>Breezy Builder | Home Page</title>
                <meta name="description" content="Breezy Builder is a free Daily Fantasy Sports (DFS) lineup builder.."></meta>
                <meta name="keywords" content="DFS, mass enter, stacking, locking, run-back, correlation, ratings, salary"></meta>
                <link rel="canonical" href="/" />
            </Helmet>
          <div className = "home">

            <h1>Welcome to Breezy Builder!</h1>
            <h2>Rate and stack players to build 1 to 150 DFS lineups.</h2>
            <br></br>
            <h3>Sport</h3>
       
            <div className="div-flex">  
                <RadioListImage
                  name="MLB"
                  id="MLB"
                  value="MLB"
                  text="https://bbplayerimages.blob.core.windows.net/public/MLB.png"
                  title="MLB"
                  alt="MLB"
                  onChange={onChangeSport}
                  checked={sport === "MLB"}
                />  
                <RadioListImage
                    name="NFL"
                    id="NFL"
                    value="NFL"
                    text="https://bbplayerimages.blob.core.windows.net/public/NFL.png"
                    title="NFL"
                    alt="NFL"
                    onChange={onChangeSport}
                    checked={sport === "NFL"}
                />
                <RadioListImage
                  name="PGA"
                  id="PGA"
                  value="PGA"
                  text="https://bbplayerimages.blob.core.windows.net/public/PGA.png"
                  title="PGA"
                  alt="PGA"
                  onChange={onChangeSport}
                  checked={sport === "PGA"}
                />    
            </div>
            <br></br> {
            (2===1) &&
                <div><label className='centerRedMessage'>{sportMessage}</label></div>
            }
            <h3>Slate</h3>
            <div className="div-inline">
                <Select className="custom-select" options={ddlSlates} label="Select Slate" value={ddlSlateSelected} onChange={ddlSlateChangeHandler} isSearchable={false} ></Select> 
            </div>

            <br></br>      
            <br></br>

            <h3>Number of Lineups</h3>
     
            <div className="div-flex">    
                <RadioList
                    name="One"
                    id="One"
                    value=""
                    text="One at a time"
                    onChange={onChangeOneVsMany}
                    checked={oneVsMany === "One"}
                />
                <RadioList
                   name="Many"
                   id="Many"
                   value=""
                   text="Many (File Download)"
                   onChange={onChangeOneVsMany}
                   checked={oneVsMany === "Many"}
                />  
            </div>

            {
            showNext &&
                <div className="div-flexRight">
                <button className="button" onClick={handleNextClick}>Next</button>
                </div>
            }
            
          </div>
        </div>
    );
}
export default Home;